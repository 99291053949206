import { useMsal, useAccount } from "@azure/msal-react";
import { useQuery } from "react-query";
import { getAPI } from "src/utils/fetch";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

interface Notification {
  content: string;
  level: string;
  id: string;
}

export const useNotifications = () => {
  const { instance } = useMsal();
  const account = useAccount();
  const apiKey = account?.idToken;

  return useQuery<Notification[], Error>({
    queryKey: ['notifications'],
    queryFn: async () => {
      const response = await getAPI({
        url: `${BACKEND_URL}/v1/user/messages`,
        apiKey,
        instance,
      });
      const data = await response.json();
      return data;
    },
    enabled: !!apiKey,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};